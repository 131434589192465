import _ from 'lodash'
import Loading from '@/plugin/loading'
import { API } from '@/plugin/api'
import { reportEvent } from '@/plugin/metric'
import localConfig from '@/plugin/localConfig'
import { hamiVuex } from '.'

export const userStore = hamiVuex.store({
  $name: 'user',
  $state() {
    return {
      inputAccount: null,
      loading: new Loading(),
      loginUser: null,
      loginToken: null,
      loginDate: null,
      loginReturnTo: null,
    }
  },
  SET_INPUT_ACCOUNT(value) {
    this.$patch({ inputAccount: value })
  },
  LOGIN(loginUser) {
    this.$patch({ loginUser: loginUser })
  },
  CONFIGURE_PASSWORD() {
    if (!_.isNil(this.loginUser)) {
      this.$patch(state => {
        state.loginUser.has_usable_password = true
      })
    }
  },
  setLoginReturnTo(returnTo) {
    this.$patch(state => {
      state.loginReturnTo = returnTo
    })
  },
  get isLoading() {
    return this.loading.isLoading
  },
  get isLogined() {
    return !_.isNil(this.loginUser)
  },
  get username() {
    let user = this.loginUser
    return _.isNil(user) ? '' : user.username
  },
  getAuthPath() {
    let hasLoginHistory = localConfig.HAS_LOGIN_HISTORY.get()
    return hasLoginHistory ? '/login' : '/register'
  },
  async login({ account, password } = {}) {
    if (!_.isNil(account)) {
      if (this.loading.isFinished) {
        this.loading.reset()
      }
    }
    await this.loading.begin(async () => {
      let user = await API.user.login({ account, password })
      this.LOGIN(user)
      localConfig.HAS_LOGIN_HISTORY.set(true)
    })
  },
  async register({ username, email, password }) {
    await API.user.register({ username, email, password })
    reportEvent('REGISTER')
  },
  async confirmEmail({ key }) {
    await API.user.confirmEmail({ key })
  },
  async changePassword({ password }) {
    await API.user.changePassword({ password }).then(() => {
      this.CONFIGURE_PASSWORD()
    })
  },
  async resetPassword({ email }) {
    await API.user.resetPassword({ email })
  },
  async confirmResetPassword({ token, uid, new_password }) {
    await API.user.confirmResetPassword({ token, uid, new_password })
  },
  async logout() {
    await API.user.logout()
  },
  async safeLogout() {
    // try logout first to workaround django auth issues
    // need more investigation on root cause
    try {
      await this.logout()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(`logout failed ${error}`)
    }
  },
  async loginGithub({ next, scope } = {}) {
    await API.user.loginGithub({ next, scope })
  },
  async connectGithub({ next, scope } = {}) {
    if (!this.isLogined) {
      return
    }
    await API.user.connectGithub({ next, scope })
  },
  async authorizePrepare(params) {
    return await API.user.authorizePrepare(params)
  },
  async authorizeConfirm(params) {
    return await API.user.authorizeConfirm(params)
  },
})
