<template>
  <MoLayout header>
    <MoBackHeader>
      <template v-slot:title>关于艺爪</template>
    </MoBackHeader>
    <div class="main">
      <div class="markdown-body">
        <h3 class="title">艺爪 - 创造好用的产品和服务</h3>
        <p class="about">艺爪账号是我们的统一账号系统。</p>
        <p>如果你遇到任何账号问题，可以通过以下邮箱与我们联系。</p>
        <p>官方邮箱: {{ contactEmail }}</p>
        <p>
          艺爪官网: <a :href="homeUrl" target="_blank">{{ homeUrl }}</a>
        </p>
      </div>
    </div>
  </MoLayout>
</template>

<script>
import MoLayout from '@/components/MoLayout'
import MoBackHeader from '@/components/MoBackHeader'

const contactEmail = 'hi@ezfuns.com'
const homeUrl = 'https://www.ezboti.com/'
export default {
  components: { MoLayout, MoBackHeader },
  data() {
    return { homeUrl, contactEmail }
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/common';

.main {
  padding: 16 * @pr;
  padding-bottom: 32 * @pr;
}

.about {
  margin-top: 32 * @pr;
}

.center {
  text-align: center;
}

.center-wrapper {
  display: flex;
  justify-content: center;

  ul {
    display: inline-block;
    margin-bottom: 0;
  }
}

.markdown-body a {
  color: @antTextSemi;
  display: inline-block;
  border-bottom: 1px solid lighten(@antTextSemi, 15%);
  text-decoration: none;
  line-height: 1.15;
  &:hover {
    text-decoration: none;
  }
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  padding-top: 32 * @pr;
  .link {
    margin-left: 16 * @pr;
    margin-right: 16 * @pr;
  }
}
</style>
