<template>
  <MoLayout footer>
    <div class="authorize">
      <div class="authorize-wrapper" :style="wrapperStyle">
        <div class="error-wrapper" v-if="errorText">
          <h3 class="error-title">抱歉！系统出现了问题</h3>
          <p class="error-description">
            系统可能存在配置错误或服务中断，请再试一次。如果问题仍然存在，请随时与我们联系。
          </p>
          <div class="error-detail">
            <pre class="error-text">{{ errorText }}</pre>
          </div>
        </div>
        <div class="redirect-wrapper" v-else-if="redirectURL">
          <a class="redirect-text" :href="redirectURL">授权成功，正在跳转到应用...</a>
        </div>
        <div class="confirm-wrapper" v-else-if="clientInfo">
          <p class="client-name">{{ clientInfo.name }}</p>
          <p class="confirm-info">
            <span>将使用 {{ username }} 账号登录</span>
          </p>
          <div class="action-confirm">
            <MoAntBlueButton class="button-confirm" @click="onConfirm">确认登录</MoAntBlueButton>
          </div>
          <div class="action-cancel">
            <mu-button flat :color="antBlue" class="button-cancel" @click="onCancel">取消</mu-button>
          </div>
        </div>
      </div>
      <MoFooter />
    </div>
  </MoLayout>
</template>

<script>
import { antGreen, antBlue, antTextGrey } from '@/plugin/common'
import MoLayout from '@/components/MoLayout.vue'
import MoFooter from '@/components/MoFooter.vue'
import MoAntBlueButton from '@/components/MoAntBlueButton.vue'
import { userStore } from '@/store/user'

export default {
  components: { MoLayout, MoFooter, MoAntBlueButton },
  data() {
    return {
      antGreen,
      antBlue,
      antTextGrey,
      errorText: '',
      redirectURL: '',
      clientInfo: null,
    }
  },
  computed: {
    wrapperStyle() {
      return {
        minHeight: `${this.$LAYOUT.windowInnerHeight}px`,
      }
    },
    username() {
      return userStore.username
    },
  },
  async mounted() {
    // 未登录则先跳转登录注册
    if (!userStore.isLogined) {
      userStore.setLoginReturnTo(this.$route.fullPath)
      this.$router.replace(userStore.getAuthPath())
      // return
    }
    // 已登录，获取授权应用信息
    await this.doAuthorizePrepare()
  },
  methods: {
    handleError(err) {
      let message = err.message
      if (err.response && err.response.data && err.response.data.error) {
        let errorCode = err.response.data.error
        message = `Error: ${errorCode}\n${message}`
      }
      this.errorText = message
    },
    getAuthParams() {
      let query = this.$route.query
      return {
        client_id: query.client_id,
        state: query.state,
        redirect_uri: query.redirect_uri,
        response_type: query.response_type,
      }
    },
    async doAuthorizePrepare() {
      const params = this.getAuthParams()
      let result = null
      try {
        result = await userStore.authorizePrepare(params)
      } catch (err) {
        this.handleError(err)
        return
      }
      if (result) {
        this.clientInfo = result
      }
    },
    async doAuthorizeConfirm() {
      const params = this.getAuthParams()
      let result = null
      try {
        result = await userStore.authorizeConfirm(params)
      } catch (err) {
        this.handleError(err)
        return
      }
      if (result) {
        this.redirectURL = result.url
        window.location.replace(result.url)
      }
    },
    async onCancel() {
      this.$router.push('/')
    },
    async onConfirm() {
      await this.doAuthorizeConfirm()
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/common';

.authorize {
  padding-left: 16 * @pr;
  padding-right: 16 * @pr;
}

.authorize-wrapper {
  min-height: 100vh;
  padding-bottom: @footerHeight;
}

.error-wrapper {
  padding-top: 64 * @pr;
}

.redirect-wrapper {
  padding-top: 128 * @pr;
}

.error-title {
  font-size: 18 * @pr;
  text-align: center;
}

.error-description {
  font-size: 15 * @pr;
  text-align: center;
}

.error-detail {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 32 * @pr;
  .error-text {
    font-size: 15 * @pr;
    color: @antTextGrey;
  }
}

.redirect-text {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 18 * @pr;
  color: @antTextGrey;
}

.confirm-wrapper {
  padding-top: 64 * @pr;

  .client-name {
    text-align: center;
    font-size: 24 * @pr;
    cursor: default;
  }
  .confirm-info {
    text-align: center;
    font-size: 16 * @pr;
    color: @antTextGrey;
  }
  .action-confirm,
  .action-cancel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .action-confirm {
    margin-top: 64 * @pr;
  }
  .action-cancel {
    margin-top: 24 * @pr;
  }
  .button-confirm {
    width: 216 * @pr;
    height: 44 * @pr;
    font-size: 16 * @pr;
  }
  .button-cancel {
    font-size: 16 * @pr;
  }
}
</style>
