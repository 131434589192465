import MoLoginPage from '@/views/MoLoginPage'
import MoRegisterPage from '@/views/MoRegisterPage'
import MoAccountConfirmEmailPage from '@/views/MoAccountConfirmEmailPage'
import MoResetPasswordPage from '@/views/MoResetPasswordPage'
import MoResetPasswordConfirmPage from '@/views/MoResetPasswordConfirmPage'
import MoAccountPage from '@/views/MoAccountPage'
import MoAuthorizePage from '@/views/MoAuthorizePage'
import MoAboutPage from '@/views/MoAboutPage'
import MoNotFoundPage from '@/views/MoNotFoundPage'

const routes = [
  {
    path: '/',
    name: 'Account',
    component: MoAccountPage,
    meta: { loginRequired: true },
  },
  {
    path: '/about',
    name: 'About',
    component: MoAboutPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: MoLoginPage,
  },
  {
    path: '/register',
    name: 'Register',
    component: MoRegisterPage,
  },
  {
    path: '/authorize',
    name: 'Authorize',
    component: MoAuthorizePage,
  },
  {
    path: '/confirm-account-email',
    name: 'AccountConfirmEmail',
    component: MoAccountConfirmEmailPage,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: MoResetPasswordPage,
  },
  {
    path: '/confirm-reset-password',
    name: 'ResetPasswordConfirm',
    component: MoResetPasswordConfirmPage,
  },
]
routes.push({ path: '*', component: MoNotFoundPage })

export default routes
