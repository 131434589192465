<template>
  <MoLayout header>
    <MoHeader :border="true">
      <MoDebugTool class="title">艺爪账号</MoDebugTool>
    </MoHeader>
    <div class="main">
      <div class="action-group">
        <div class="action-row">
          <span class="action-label">我的账号</span>
          <span>
            <span>{{ username }}</span>
            <fa-icon class="action-icon" icon="chevron-right" />
          </span>
        </div>
      </div>
      <div class="divider"></div>
      <div class="action-group">
        <div v-if="false" class="action-row" :class="{ disabled: isGithubConnected }" @click="connectGithub">
          <span class="action-label">GitHub登录</span>
          <span>
            <span>{{ isGithubConnected ? '已绑定' : '绑定' }}</span>
            <fa-icon class="action-icon" icon="chevron-right" />
          </span>
        </div>
        <div class="action-row" :class="{ disabled: isPasswordConfigured }" @click="configurePassword">
          <span class="action-label">密码登录</span>
          <span>
            <span>{{ isPasswordConfigured ? '已设置' : '设置密码' }}</span>
            <fa-icon class="action-icon" icon="chevron-right" />
          </span>
        </div>
        <div class="action-row" @click="goAbout">
          <span class="action-label">关于艺爪</span>
          <fa-icon class="action-icon" icon="chevron-right" />
        </div>
      </div>
      <div class="divider"></div>
      <div class="action-group">
        <div class="action-row action-logout" @click="onLogout">
          <span class="action-label">退出登录</span>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </MoLayout>
</template>

<script>
import _ from 'lodash'
import MoLayout from '@/components/MoLayout'
import MoHeader from '@/components/MoHeader'
import MoDebugTool from '@/components/MoDebugTool'
import { antGold, antRed, antGreen } from '@/plugin/common'
import { userStore } from '@/store/user'

export default {
  name: 'MoAccountPage',
  components: { MoLayout, MoHeader, MoDebugTool },
  data() {
    return {
      antGold,
      antRed,
      antGreen,
    }
  },
  computed: {
    isGithubConnected() {
      let user = userStore.loginUser
      if (!userStore.isLogined || _.isNil(user)) {
        return false
      }
      if (_.isNil(user.social_accounts)) {
        return false
      }
      for (let i = 0; i < user.social_accounts.length; i++) {
        let acc = user.social_accounts[i]
        if (_.isEqual(acc.provider, 'github')) {
          return true
        }
      }
      return false
    },
    isPasswordConfigured() {
      let user = userStore.loginUser
      if (!userStore.isLogined || _.isNil(user)) {
        return false
      }
      return _.isNil(user.has_usable_password) || user.has_usable_password
    },
    username() {
      return userStore.username
    },
  },
  mounted() {},
  methods: {
    configurePassword() {
      if (this.isPasswordConfigured) {
        return
      }
      this.$prompt(null, '请输入新密码', {
        inputType: 'password',
        beforeClose: (result, instance, done) => {
          if (!result) {
            return done()
          }
          userStore
            .changePassword({ password: instance.value })
            .then(() => done())
            .catch(error => {
              instance.errorText = error.message
            })
        },
      }).then(({ result }) => {
        if (result) {
          this.$toast.success('密码设置成功')
        }
      })
    },
    connectGithub() {
      if (this.isGithubConnected) {
        return
      }
      this.$loading({ size: 24 })
      userStore.connectGithub({ next: '/' })
    },
    goAbout() {
      this.$router.push('/about')
    },
    onLogout() {
      userStore.logout().then(() => {
        window.location.assign('/')
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/common';

.main {
  padding-top: 8 * @pr;
}

.title {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  color: @antTextBlack;
  font-weight: bold;
  font-size: 16 * @pr;
  cursor: default;
}

.divider {
  margin-top: 8 * @pr;
  margin-bottom: 8 * @pr;
  border-bottom: solid 1 * @pr lighten(@antLineGrey, 3%);
}

.action-group {
  background: @antBackWhite;
}

.action-group-vip {
  margin-bottom: 8 * @pr;
  background: lighten(@antGold, 48%);
  color: @antTextBlack;
}

.balance-not-enough .vip-balance {
  color: @antGold;
  font-weight: bold;
}

.action-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64 * @pr;
  padding-left: 16 * @pr;
  padding-right: 16 * @pr;
  cursor: pointer;
}

.action-row.disabled {
  opacity: 0.8;
  color: @antTextGrey;
  cursor: not-allowed;
}

.action-label {
  font-weight: bold;
  font-size: 16 * @pr;
}

.action-icon {
  margin-left: 8 * @pr;
  color: lighten(@antTextSemi, 10%);
  position: relative;
  top: 1 * @pr;
}
</style>
