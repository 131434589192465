import _ from 'lodash'
import axios from 'axios'
import Cookies from 'js-cookie'
import Toast from 'muse-ui-toast'

import Timeit from './timeit'
import localConfig from './localConfig'

function isDebug() {
  return localConfig.DEBUG.get()
}

const BASE_URL = '/api/v1'

const client = axios.create({
  baseURL: BASE_URL,
})

const REQUEST_ITERCEPTORS = [
  function(config) {
    let csrftoken = Cookies.get('csrftoken')
    if (!_.isNil(csrftoken)) {
      if (_.isNil(config.headers)) {
        config.headers = {}
      }
      config.headers['X-CSRFToken'] = csrftoken
    }
    return config
  },
]
REQUEST_ITERCEPTORS.forEach(x => {
  client.interceptors.request.use(x)
})

const RESPONSE_INTERCEPTORS = [
  [
    function(response) {
      if (isDebug()) {
        let time = response.headers['x-time']
        if (!_.isNil(time)) {
          Timeit.show(time, response.config.method, response.config.url)
        }
      }
      return Promise.resolve(response.data)
    },
    function(error) {
      let title = null
      if (_.isNil(error.response)) {
        title = `Failed: ${error.config.method} ${error.config.url}`
      } else {
        title = `${error.response.status} ${error.response.statusText}`
      }
      let message = error.message
      if (!_.isNil(error.response)) {
        if (!_.isNil(error.response.data)) {
          if (!_.isEmpty(error.response.data.message)) {
            message = error.response.data.message
          } else if (!_.isEmpty(error.response.data.detail)) {
            message = error.response.data.detail
          } else if (error.response.status >= 400 && error.response.status < 500) {
            message = JSON.stringify(error.response.data)
          }
        }
      }
      message = _.truncate(message, { length: 140, separator: /,? +/ })
      Object.assign(error, { title, message })
      if (isDebug()) {
        Toast.error(title + ': ' + message)
      }
      return Promise.reject(error)
    },
  ],
]
RESPONSE_INTERCEPTORS.forEach(pair => {
  client.interceptors.response.use(...pair)
})

function convertDjangoErrorMessage(error, fields) {
  if (!error.response || error.response.status !== 400) {
    return error.message
  }
  let data = error.response.data
  let message = []
  for (let field of fields) {
    if (_.isArray(data[field])) {
      message = message.concat(data[field])
    } else if (!_.isEmpty(data[field])) {
      message.push(data[field])
    }
  }
  message = message.slice(0, 1).join(' ')
  return message
}

function convertEmailMessage(emailMessage) {
  if (!_.isEmpty(emailMessage)) {
    if (emailMessage.toLowerCase().includes('already registered')) {
      emailMessage = '此邮箱已注册'
    } else if (emailMessage.toLowerCase().includes('valid email address')) {
      emailMessage = '邮箱地址无效'
    }
  }
  return emailMessage
}

function convertPasswordMessage(passwordMessage) {
  if (!_.isEmpty(passwordMessage)) {
    if (passwordMessage.toLowerCase().includes('password is too short')) {
      passwordMessage = '密码至少要 8 个字符'
    } else if (passwordMessage.toLowerCase().includes('password is too common')) {
      passwordMessage = '密码不能太简单'
    } else if (passwordMessage.toLowerCase().includes('password is too similar to the username')) {
      passwordMessage = '密码不能和用户名太相似'
    }
  }
  return passwordMessage
}

const API = {
  user: {
    login({ account, password } = {}) {
      return client.post('/user.login', { account, password })
    },
    register({ username, email, password }) {
      username = _.defaultTo(username, email)
      return client
        .post('/auth/registration/', {
          username,
          email,
          password1: password,
          password2: password,
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            let emailMessage = convertDjangoErrorMessage(error, ['email', 'username'])
            emailMessage = convertEmailMessage(emailMessage)
            let passwordMessage = convertDjangoErrorMessage(error, ['password1', 'password2', 'non_field_errors'])
            passwordMessage = convertPasswordMessage(passwordMessage)
            if (emailMessage || passwordMessage) {
              error.response.data = {
                email: emailMessage,
                password: passwordMessage,
              }
            }
          }
          throw error
        })
    },
    confirmEmail({ key }) {
      return client.post('/auth/registration/verify-email/', { key })
    },
    changePassword({ password }) {
      return client
        .post('/auth/password/change/', { new_password1: password, new_password2: password })
        .catch(error => {
          let passwordMessage = convertDjangoErrorMessage(error, ['new_password1', 'new_password2'])
          passwordMessage = convertPasswordMessage(passwordMessage)
          if (!_.isEmpty(passwordMessage)) {
            error.message = passwordMessage
          }
          throw error
        })
    },
    resetPassword({ email }) {
      return client.post('/auth/password/reset/', { email }).catch(error => {
        let emailMessage = convertDjangoErrorMessage(error, ['email'])
        emailMessage = convertEmailMessage(emailMessage)
        if (!_.isEmpty(emailMessage)) {
          error.message = emailMessage
        }
        throw error
      })
    },
    confirmResetPassword({ token, uid, new_password }) {
      return client
        .post('/auth/password/reset/confirm/', {
          token,
          uid,
          new_password1: new_password,
          new_password2: new_password,
        })
        .catch(error => {
          let passwordMessage = convertDjangoErrorMessage(error, ['new_password1', 'new_password2', 'token', 'uid'])
          passwordMessage = convertPasswordMessage(passwordMessage)
          if (!_.isEmpty(passwordMessage)) {
            error.message = passwordMessage
          }
          throw error
        })
    },
    logout() {
      return client.post(`/auth/logout/`)
    },
    async authorizeConfirm(params) {
      return await client.post(`/authorize.confirm`, params)
    },
    async authorizePrepare(params) {
      return await client.post(`/authorize.prepare`, params)
    },
    async loginGithub({ next, scope } = {}) {
      let result = await client.post(`/user.github_authorize`, {
        next,
        scope,
        process: 'login',
      })
      window.location.assign(result.url)
    },
    async connectGithub({ next, scope } = {}) {
      let result = await client.post(`/user.github_authorize`, {
        next,
        scope,
        process: 'connect',
      })
      window.location.assign(result.url)
    },
  },
}

export default API
export { API, BASE_URL, REQUEST_ITERCEPTORS, client }
